$(document).on('turbolinks:load', function() {
  $('[name="select_all_fst"]').on('click', function() {
    $('input[value="must_have"]').prop('checked', true);
  });
  $('[name="select_all_sec"]').on('click', function() {
    $('input[value="nice_to_have"]').prop('checked', true);
  });
  $('[name="select_all_thr"]').on('click', function() {
    $('input[value="dont_want"]').prop('checked', true);
  });
});
