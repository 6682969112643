$(document).on('turbolinks:load', function() {
  $('.view-all-cars').on('click', function() {
    let btn_parent_node = $(this).parent();
    let carousel_items = btn_parent_node.siblings('div').children('.carousel-item');
    let left_right_arrows = btn_parent_node.siblings('a');
    if ($(this).prop('value').includes('View All')) {
      carousel_items.addClass('active');
      left_right_arrows.addClass('d-none');
      $(this).prop('value', 'View Less \u00A0 <');
    }
    else {
      carousel_items.removeClass('active');
      carousel_items.first().addClass('active');
      btn_parent_node.siblings('a').removeClass('d-none');
      $(this).prop('value', 'View All \u00A0 >'); 
    }
  });
});
