budget_sliders = (is_budget_page, is_search_page) => {

  var term_label_map = {'2': 1, '3': 2, '4': 3, '5': 4, '6': 5, '7': 6};
  var car_price_label_map = { '10000': 1, '15000': 2, '20000': 3, '25000': 4, '30000': 5, '35000': 6, '40000': 7, '45000':8, '50000':9, '55000':10, '60000': 11 };
  var down_payment_label_map = { '500': 1, '1000': 2, '1500': 3, '2000': 4, '2500': 5, '3000': 6 };

  const interest_rate = 3.25;

  var default_slider_values = $('.label-is-selection');

  var term_slider = $('#term_selector');
  var cash_purchase_price_slider = $("#cash_purchase_price_selector");
  var down_payment_slider = $('#down_payment_selector');

  var term_text_field = $("#term");
  var car_purchasing_price_text_field = $("#cash_purchase_price");
  var down_payment_text_field = $("#down_payment");

  var term_hidden_field = $('#search_term');
  var car_purchasing_price_hidden_field = $('#search_car_purchasing_price');
  var down_payment_hidden_field = $('#search_down_payment');

  var monthly_payment_node = $('#monthly-payment-value');


  numeric_form = (string) => {
    return string.replace(/\D/g,'');
  }

  display_monthy_payment = (term, car_purchasing_price, down_payment, monthly_payment_node) => {
    var term = parseFloat(term) * 12;
    var car_purchasing_price = parseFloat(car_purchasing_price);
    var down_payment = parseFloat(down_payment);

    let amount_financed = car_purchasing_price - down_payment;
    let step1 = (interest_rate / 100);
    let step2 = (step1 / 12);
    let step3 = (step2 * amount_financed);
    let step4 = step2 + 1;
    let step5 = Math.pow(step4, term);
    let step6 = (1 / step5);
    let step7 = (1 - step6);
    let monthly_payment = (step3/step7);

    monthly_payment_node.text(`$${monthly_payment.toFixed(2)}`);
  }

  set_sliders = () => {
    term_slider.slider('setValue', term_label_map[term_hidden_field.val()]);
    cash_purchase_price_slider.slider('setValue', car_price_label_map[car_purchasing_price_hidden_field.val()]);
    down_payment_slider.slider('setValue', down_payment_label_map[down_payment_hidden_field.val()]);
  }

  if (is_search_page) {
    $('#budget-dropdown').on('click', function() {
      set_sliders();
    });
  }

  if (is_budget_page) {
    $('#down_payment_selector').on('DOMSubtreeModified', function() {
      setTimeout(function() {
        set_sliders();
      }, 200);
    });
  }

  $('#budget-submit-btn').on('click', function() {
    $('#search_monthly_payment').prop('value', monthly_payment_node.text().substring(1));
  });

  term_slider.on("change", function(slideEvt) {
    let slider_value = $(this).data('slider-ticks-labels')[slideEvt.value.newValue - 1];
    let numeric_slider_value = numeric_form(slider_value);
    term_text_field.val(slider_value);
    term_hidden_field.prop('value', numeric_slider_value);

    display_monthy_payment(
      term_hidden_field.val(),
      car_purchasing_price_hidden_field.val(),
      down_payment_hidden_field.val(),
      monthly_payment_node
    )
  });

  cash_purchase_price_slider.on("change", function(slideEvt) {
    let slider_value = $(this).data('slider-ticks-labels')[slideEvt.value.newValue - 1];
    var cash_price_match = slider_value.match(/(\d+)/);
    let numeric_slider_value = numeric_form(cash_price_match[0]);
    car_purchasing_price_text_field.val("$"+cash_price_match[0]+"000");
    car_purchasing_price_hidden_field.prop('value', numeric_slider_value*1000);

    display_monthy_payment(
      term_hidden_field.val(),
      car_purchasing_price_hidden_field.val(),
      down_payment_hidden_field.val(),
      monthly_payment_node
    )

  });

  down_payment_slider.on("change", function(slideEvt) {
    let slider_value = $(this).data('slider-ticks-labels')[slideEvt.value.newValue - 1];
    let numeric_slider_value = numeric_form(slider_value);
    down_payment_text_field.val(slider_value);
    down_payment_hidden_field.prop('value', numeric_slider_value);

    display_monthy_payment(
      term_hidden_field.val(),
      car_purchasing_price_hidden_field.val(),
      down_payment_hidden_field.val(),
      monthly_payment_node
    )
  });
}


$(document).on('turbolinks:load', function() {
  var current_screen_url = window.location.pathname;
  is_budget_page = current_screen_url.includes('budget');
  is_search_page = current_screen_url.includes('search');

  $('#back-to-budget').on('click', function() {
    setTimeout(function() {
      budget_sliders(is_budget_page, is_search_page);
    }, 300);
  });

  $('#budget-dropdown').on('click', function() {
    budget_sliders(is_budget_page, is_search_page);
  });

  if (is_budget_page || is_search_page){
    budget_sliders(is_budget_page, is_search_page);
  }

});
