async function calculate_criteria_scores(vehicles_matching_features, actual_safety_features, search_id, number_of_features, searched_safety_features_vals) {
  let matching_score
  let resultant_scores = {};
  resultant_scores[search_id] = {};

  return new Promise((resolve, reject) => {
    $.map(vehicles_matching_features, (vehicle_features, vehicle_id) => {
      matching_score = 0;
      $.map(actual_safety_features, (actual_safety_feature, i) => {
        let safety_feature = searched_safety_features_vals[i];
        let is_feature_present_in_vehicle = vehicle_features.includes(actual_safety_feature);
        if (is_feature_present_in_vehicle) {
          safety_feature == 'dont_want' ? matching_score += 0 : matching_score += 2;
        }
        else {
          if (safety_feature == 'must_have') {
            matching_score += 1;
          } else {
            safety_feature == 'dont_want' ? matching_score += 3 : matching_score += 1;
          }
        }
      });
      matching_score = ((parseFloat(matching_score)/number_of_features)*100).toFixed();
      matching_score = matching_score < 0 ? 0 : matching_score;
      matching_score = matching_score > 100 ? 95 : matching_score;

      resultant_scores[search_id][vehicle_id] = matching_score;
    });
    resolve(resultant_scores);
  });
}

async function wait_for_safety_features_node(selector) {
  return new Promise(resolve => {
    if ($(selector)) {
      return resolve($(selector));
    }

    const observer = new MutationObserver(mutations => {
      if ($(selector)) {
        resolve($(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

async function wait_for_search_id(safety_features_node) {
  return new Promise(resolve => {
    let search_id = safety_features_node.data('search-id');
    if (search_id) {
      return resolve(search_id.toString());
    }

    const observer = new MutationObserver(mutations => {
      if (search_id) {
        resolve(search_id.toString());
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

let set_criteria_match_score = () => {
  wait_for_safety_features_node('#vehicle-matching-features').then((safety_features_node) => {
    wait_for_search_id(safety_features_node).then((search_id) => {
      let vehicles_matching_features = safety_features_node.data('matching-features');
      let searched_safety_features = safety_features_node.data('searched-safety-features');
      let safety_features = safety_features_node.data('safety-features');
      let searched_safety_features_vals = Object.values(searched_safety_features);
      let actual_safety_features = Object.values(safety_features);
      let number_of_features = 44;

      calculate_criteria_scores(
        vehicles_matching_features,
        actual_safety_features,
        search_id,
        number_of_features,
        searched_safety_features_vals
      ).then(
        (search_criteria_scores) => {
          display_criteria_match(search_criteria_scores[search_id]);
      });
    });
  });
}

let display_criteria_match = (scores) => {
  let top_vehicle_id = $('#top-criteria-match').data('top-match-id').toString();

  $.map(scores, (matching_score, vehicle_id) => {
    let class_to_be = `radialProgressBar radialProgressBar-84 progress-yellow-${Math.ceil(matching_score/5)*5}`;
    $(`#criteria-match-${vehicle_id}`).html(`${matching_score}%`);
    $(`#circular-bar-${vehicle_id}`).prop('class', class_to_be);

    let vehicle_details_btn = $(`#details-page-btn-${vehicle_id}`);
    let vehicle_details_btn_path = vehicle_details_btn.prop('href');
    vehicle_details_btn.prop('href', `${vehicle_details_btn_path.split('?')[0]}?criteria_match=${matching_score}`);

    if (vehicle_id == top_vehicle_id) {
      $('#top-criteria-match').html(`${matching_score}%`);
      $('#top-circular-bar').prop('class', class_to_be.replace('radialProgressBar-84', ''));

      let top_vehicle_details_btn = $('#top-details-page-btn');
      let top_vehicle_details_btn_path = top_vehicle_details_btn.prop('href');
      top_vehicle_details_btn.prop('href', `${top_vehicle_details_btn_path.split('?')[0]}?criteria_match=${matching_score}`);
    }
  });
}

$(document).on('turbolinks:load', () => {
  if ($('.match_me-search').length > 0) {
    set_criteria_match_score();
  }
});
