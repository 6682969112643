$(document).on('turbolinks:load', function() {

  let finance_items = $('.financing-type');
  let insurance_items = $('.insurance-type');  

  $('#all-type').on('click', function() {
    finance_items.removeClass('d-none');
    insurance_items.removeClass('d-none');
  });
  $('#insurance-type').on('click', function() {
    finance_items.addClass('d-none');
    insurance_items.removeClass('d-none');
  });
  $('#financing-type').on('click', function() {
    insurance_items.addClass('d-none');
    finance_items.removeClass('d-none');
  });
});
