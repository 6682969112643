$(document).on('turbolinks:load', function() {
  const articlesPerPage = 2;
  $('.view-one-more-article').on('click', function() {
    let hiddenArticles = $('#categorized-articles').find('.wordpress-article.d-none'); 
    if (hiddenArticles.length <= articlesPerPage) {
      hiddenArticles.removeClass('d-none');
      if ($('.wordpress-article.d-none').length == 0) $(this).hide();
    }
    else {
      hiddenArticles = hiddenArticles.slice(0, articlesPerPage);
      hiddenArticles.removeClass('d-none');
    }
  })
});
