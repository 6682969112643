setup_slider = (is_search_page, is_details_page) => {

    window.jssor_1_slider_init = function() {
      var jssor_1_options = {
        $AutoPlay: 0,
        $AutoPlaySteps: 5,
        $SlideDuration: 160,
        $SlideWidth: (is_search_page ? 150 : 190),
        $SlideSpacing: 3,
        $ArrowNavigatorOptions: {
          $Class: $JssorArrowNavigator$,
          $Steps: 5
        },
        $BulletNavigatorOptions: {
          $Class: $JssorBulletNavigator$,
          $SpacingX: 16,
          $SpacingY: 16
        }
      };

      var jssor_1_slider = new $JssorSlider$("jssor_1", jssor_1_options);

      /*#region responsive code begin*/

      var MAX_WIDTH = 1000;

      function ScaleSlider() {
        var containerElement = jssor_1_slider.$Elmt.parentNode;
        var containerWidth = containerElement.clientWidth;

        if (containerWidth) {
          var expectedWidth = Math.min(MAX_WIDTH || containerWidth, containerWidth);
          jssor_1_slider.$ScaleWidth(expectedWidth);
        }
        else {
          window.setTimeout(ScaleSlider, 30);
        }
      }

      ScaleSlider();

      $Jssor$.$AddEvent(window, "load", ScaleSlider);
      $Jssor$.$AddEvent(window, "resize", ScaleSlider);
      $Jssor$.$AddEvent(window, "orientationchange", ScaleSlider);
      /*#endregion responsive code end*/
    };
    jssor_1_slider_init();
    
    // prevent the div to close in search forms.
    $(".select-opt, .dropdown-carbody, .jssora073, #mega-dropdown").on('click', function (event) {
      event.stopPropagation();
    });

}

$(document).on('turbolinks:load', function() {
  let current_url = window.location.pathname;
  let is_search_page = current_url.includes('search');
  let is_details_page = current_url.includes('vehicles');

  if (is_search_page || is_details_page){
    setup_slider(is_search_page, is_details_page);
    setTimeout(function() {
      $('#mega-dropdown').removeClass('show');
    }, 150);
  }

});
