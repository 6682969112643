$(document).on('turbolinks:load', function() {
  let current_path = window.location.pathname;
  if (current_path.includes('search')) {
    $('body').on('click', function(e) {
      let compare_car_div = $(".dropdown-menu.dropdown-menu-right.show");
      let nodes_ids = ['balance-scale', 'dropdownMenuLink compare-dropdown', 'top-match-compare-btn', 'dropdownMenuLink compare-dropdown top-match-compare-btn'];
      let is_compare_dropdown_btn_close = (e.target == $('#compare-dropdown-close')[0]);
      let to_remove = !compare_car_div.is(e.target) && (compare_car_div.has(e.target).length === 0) && (!nodes_ids.includes(e.target.id));

      if (to_remove || is_compare_dropdown_btn_close) compare_car_div.remove();
    });
  }
  else if (current_path.includes('vehicles')) {
    $('#add-to-compare-btn, #compare-dropdown-close').on('click', function() {
      $('#compared-dropdown').toggleClass('show');
    });
  }
});
